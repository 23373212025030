import { ListItem } from '@local/web-design-system/dist/components/GenericListing';

import { usePersistedState } from 'src/hooks/usePersistedState';

import { objectDefinition } from './FieldRowDefinitions';
import { ListedObjectDisplay } from './utils';

export function ObjectRow({
    object,
    displayEmptyActions = false,
}: {
    object: ListedObjectDisplay;
    displayEmptyActions?: boolean;
}) {
    const [id, setID] = usePersistedState('id');

    const isSelected = id === object?.object_id;

    const handleRowClick = () => {
        if (isSelected) {
            setID(undefined);
        } else {
            setID(object?.object_id);
        }
    };

    return (
        <ListItem
            item={object}
            fields={objectDefinition}
            clickHandler={handleRowClick}
            selected={isSelected}
            displayEmptyActions={displayEmptyActions}
        />
    );
}
