import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { BoundingBoxControls } from '@local/map-viewer/dist/controls/BoundingBoxControls';
import { ClusteredObjectMarkers } from '@local/map-viewer/dist/layers/evo/clustering';
import { Loading } from '@local/map-viewer/dist/layers/Loading';
import { Search } from '@local/map-viewer/dist/layers/Search';
import { MapViewer } from '@local/map-viewer/dist/MapViewer';
import { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { Breadcrumb, BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { discoveryBreadcrumb } from 'src/utils/breadcrumbs';

import { DISCOVERY_TITLE } from '../../strings';
import { SchemaIcon } from '../workspacePage/workspaceContent/SchemaIcon';
import { MetadataPanel } from './metadataPanel/MetadataPanel';

export function DiscoverPage() {
    const [query, setQuery] = useState('');
    const [bounds, setBounds] = useState<BoundingBoxCoords | null>();
    const [boundingBox, setBoundingBox] = useState<BoundingBoxCoords | null>();
    const { toggleActiveObject, objects, setSearchBounds, isLoading, isFetching } =
        useDiscoverContext();

    // We provide either a specified bounding box, or the map's viewport (bounds) as a query filter
    useEffect(() => {
        setSearchBounds(boundingBox ?? bounds);
    }, [boundingBox, bounds]);

    setDocumentTitle('Discover');

    const segments: BreadcrumbSegment[] = [discoveryBreadcrumb];

    return (
        <Grid container>
            <Breadcrumb segments={segments} medium />
            <TitleRow title={DISCOVERY_TITLE} />
            <MapViewer onBoundsChange={setBounds} setQuery={setQuery}>
                <Search query={query} setQuery={setQuery} />
                <BoundingBoxControls onChange={setBoundingBox} />
                {isLoading || (isFetching && <Loading />)}
                <ClusteredObjectMarkers
                    objects={objects}
                    iconCreateFunc={SchemaIcon}
                    onSingleObjectClick={toggleActiveObject}
                />
                <MetadataPanel />
            </MapViewer>
        </Grid>
    );
}
