import {
    OrgListedObject,
    useGetObjectByIdQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { CloseIcon, OpenIcon } from '@local/web-design-system/dist/icons';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormattedDate } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import {
    METADATA_DESCRIPTION_TITLE,
    METADATA_DETAILS_TITLE,
    METADATA_FIELD_CREATED_BY,
    METADATA_FIELD_CREATED_ON,
    METADATA_FIELD_TYPE,
    METADATA_FIELD_WORKSPACE,
    WEBVIZ_BUTTON_DISCOVERY,
    NO_ACCESS_DESCRIPTION,
} from 'src/strings';
import { WEBVIZ_PLOT } from 'src/urls';

import { useStyles } from './MetadataPanel.styles';

function extractSchemaType(schema: string) {
    const schemaType = schema.split('/')[2];
    const formattedSchema = schemaType
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    return `${formattedSchema}`;
}

function getRelativeEvoViewerPath(activeObject: OrgListedObject) {
    const currentHub = getHubForCurrentOrg();
    return `workspaces/${currentHub.code}/${activeObject.workspace_id}/${WEBVIZ_PLOT}?id=${activeObject.object_id}`;
}

export function MetadataPanel() {
    const { activeObject, setActiveObject } = useDiscoverContext();
    const { classes } = useStyles();
    const params = useParams();
    const { evouiEnableGooseRendering } = useFlags();

    const { data: gooseData, isFetching } = useGetObjectByIdQuery(
        {
            objectId: activeObject?.object_id ?? '',
            orgId: getOrgUuidFromParams(params),
            workspaceId: activeObject?.workspace_id ?? '',
            includeVersions: false,
        },
        { skip: !activeObject },
    );

    if (!activeObject) {
        return null;
    }

    const objectDescription = activeObject.workspace_access
        ? gooseData?.object?.description ?? 'No description available'
        : NO_ACCESS_DESCRIPTION;

    return (
        <Grid
            container
            className={classes.base}
            direction="column"
            automation-id="object-metadata-panel"
        >
            <Grid item container direction="row" wrap="nowrap" alignItems="center">
                <Grid item overflow="hidden" flexGrow={1}>
                    <Typography variant="h2" noWrap>
                        {activeObject.name.substring(0, activeObject.name.length - 5)}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                        size="small"
                        classes={{ root: classes.closeButton }}
                        onClick={() => setActiveObject(undefined)}
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container className={classes.detailsBase} gap={2} direction="column">
                <Grid item container direction="column" gap={2}>
                    <Typography variant="body1">{METADATA_DETAILS_TITLE}</Typography>
                    <Grid
                        item
                        container
                        className={classes.descriptionContainer}
                        direction="column"
                        flexWrap="nowrap"
                    >
                        <Grid item>
                            <Typography variant="body2">{METADATA_DESCRIPTION_TITLE}</Typography>
                        </Grid>
                        <Grid item className={classes.descriptionTextContainer}>
                            <Typography
                                variant="body1"
                                className={classes.descriptionText}
                                automation-id="object-metadata-panel-description"
                            >
                                {activeObject.workspace_access && isFetching ? (
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="100%"
                                            className={classes.descriptionSkeleton}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width="60%"
                                            className={classes.descriptionSkeleton}
                                        />
                                    </div>
                                ) : (
                                    objectDescription
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid item container direction="row" gap={2} display="flex">
                    <Grid item direction="column">
                        <Typography variant="body1">{METADATA_FIELD_TYPE}:</Typography>
                        <Typography variant="body1">{METADATA_FIELD_CREATED_BY}:</Typography>
                        <Typography variant="body1">{METADATA_FIELD_CREATED_ON}:</Typography>
                        <Typography variant="body1">{METADATA_FIELD_WORKSPACE}:</Typography>
                    </Grid>
                    <Grid item flexGrow={1} direction="column">
                        <Grid item>
                            <Typography variant="body1" noWrap>
                                {extractSchemaType(activeObject.schema)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" noWrap>
                                {activeObject.created_by?.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" noWrap>
                                <FormattedDate
                                    value={activeObject.created_at}
                                    month="short"
                                    year="numeric"
                                    day="2-digit"
                                />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" noWrap>
                                {activeObject.workspace_name ?? ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {evouiEnableGooseRendering && activeObject.workspace_access && (
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    classes={{ root: classes.webvizButton }}
                    onClick={() => window.open(getRelativeEvoViewerPath(activeObject), '_blank')}
                    automation-id="object-metadata-panel-evo-viewer-button"
                >
                    <OpenIcon />
                    <Typography variant="body1" paddingLeft="16px">
                        {WEBVIZ_BUTTON_DISCOVERY}
                    </Typography>
                </Button>
            )}
        </Grid>
    );
}
