import { FilterIcon } from '@local/web-design-system/dist/icons/Geometry/FilterIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { ArtifactColorIndicatorControl } from '@local/webviz/dist/components/ArtifactColorIndicatorControl';
import { useBaseXyz } from '@local/webviz/dist/context';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { useAppSelector } from 'src/store/store';
import { objectListSelection } from 'src/store/visualization/selectors';
import { useScenePanel } from 'src/visualization/context/hooks/useScenePanel';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';
import { tokenProvider } from 'src/visualization/Plot/Plot';

import { SceneListItemMenu } from '../SceneListItemMenu/SceneListItemMenu';
import { ObjectVisibilityControl } from './ObjectVisibilityControl';
import { FILTERED_DRILLHOLE_SUFFIX } from './SceneListItemControl.constants';
import { useStyles } from './SceneListItemControl.styles';
import { SceneListItemControlProps } from './SceneListItemControl.types';

export function SceneListItemControl({
    objectId,
    name = '',
    fullSchema,
}: SceneListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('list-item');

    const viewId = getViewIdFromObjectId(objectId, fullSchema);
    const selectedObjectIds = useAppSelector(objectListSelection);
    const selected = selectedObjectIds.includes(objectId);

    const listItemControlRef = useRef<HTMLDivElement>(null);

    const { handleDoubleClick, handleKeyUp, handleObjectSelect } = useScenePanel(objectId, viewId);
    const [showOverflowMenu, setShowOverflowMenu] = useState(false);
    const [isRendered, setIsRendered] = useState(false);
    const { addViewStatusListener, entityExists } = useBaseXyz();

    const isViewReady = entityExists(viewId);
    useEffect(() => {
        const removeViewStatusListener = addViewStatusListener({
            viewId,
            onPending: () => setIsRendered(false),
            onComplete: () => setIsRendered(true),
            onError: () => setIsRendered(false),
        });

        return () => {
            removeViewStatusListener();
        };
    }, [isViewReady]);

    useEffect(() => {
        if (selected && isRendered && listItemControlRef?.current) {
            listItemControlRef.current.scrollIntoView();
        }
    }, [selected]);

    const Icon = SchemaIcon(fullSchema);

    // TODO: set has hole id attribute and drillhole filter open
    const hasHoleIdAttribute = false; // useSelector(artifactHasHoleIdAttribute(artifactId));
    const drillholeFilterOpen = false; // useSelector(doesOpenDrillholeFilterApplyToArtifact(artifactId));
    const filterApplies = hasHoleIdAttribute && drillholeFilterOpen;

    // TODO: Wait for CENPLAT-19920 to be implemented
    const filteredDrillholes = 0; // useSelector(getFilteredDrillholes(artifactId));
    const totalDrillholes = 0; // useSelector(getTotalDrillholes(artifactId));
    const showDrillholeFilterIcon = totalDrillholes !== filteredDrillholes;
    const drillholeFilterItemTooltip = `${filteredDrillholes}/${totalDrillholes} ${FILTERED_DRILLHOLE_SUFFIX}`;

    return (
        <Grid
            item
            container
            className={classnames(classes.root, {
                [classes.rootDisabled]: !isRendered,
                [classes.filterApplies]: filterApplies,
                [classes.itemSelected]: selected,
            })}
            onKeyUp={handleKeyUp}
            onMouseEnter={() => setShowOverflowMenu(true)}
            onMouseLeave={() => setShowOverflowMenu(false)}
            tabIndex={0}
            ref={listItemControlRef}
            {...applyTrace(objectId)}
        >
            <Grid
                container
                item
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
                className={classes.listItemControl}
            >
                <Grid item className={classes.visibilityToggleButtonContainer}>
                    <ObjectVisibilityControl viewId={viewId} disabled={!isRendered} />
                </Grid>
                <Grid item className={classes.colorBarContainer}>
                    <ArtifactColorIndicatorControl
                        viewIds={[viewId]}
                        tokenProvider={tokenProvider}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className={classnames(classes.mainContainer, {
                        [classes.clickable]: isRendered,
                    })}
                    alignItems="center"
                    onClick={handleObjectSelect}
                    onDoubleClick={handleDoubleClick}
                >
                    <Grid
                        item
                        container
                        direction="row"
                        xs
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.nameContainer}
                    >
                        {Icon && <Icon fontSize="small" />}
                        <Typography variant="body2" color="inherit" className={classes.name}>
                            {name}
                        </Typography>
                    </Grid>
                    {showDrillholeFilterIcon && (
                        <Tooltip
                            title={drillholeFilterItemTooltip}
                            placement="right-start"
                            leaveDelay={500}
                        >
                            <Grid item>
                                <FilterIcon fontSize="inherit" />
                            </Grid>
                        </Tooltip>
                    )}
                    <Grid
                        className={classes.menuContainer}
                        {...applyTrace(`overflow-menu-${viewId}`)}
                    >
                        {showOverflowMenu && (
                            <SceneListItemMenu
                                objectId={objectId}
                                viewId={viewId}
                                onClose={() => setShowOverflowMenu(false)}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.paddedContainer} />
                </Grid>
            </Grid>
        </Grid>
    );
}
