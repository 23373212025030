import { useBaseXyz } from '@local/webviz/dist/context';
import { handleMultiSelect } from '@local/webviz/dist/utilities';
import { useState } from 'react';

import { store, useAppDispatch, useAppSelector } from 'src/store/store';
import {
    lastSelectedObjectId,
    loadedObjectsMap,
    objectListSelection,
    objectListShiftSelection,
} from 'src/store/visualization/selectors';
import {
    multiSelectObject,
    selectObject,
    unselectObject,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { SelectObject } from 'src/store/visualization/visualizationSlice.types';

export function useScenePanel(objectId: string, viewId: string) {
    const dispatch = useAppDispatch();
    const { entityExists, useXyzListener, getZoomToViewTool, disposeEntity } = useBaseXyz();
    const isRendered = entityExists(viewId);
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    const handleObjectSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isRendered) {
            return;
        }
        const lastSelectedId = lastSelectedObjectId(store.getState());
        const selected = objectListSelection(store.getState()).includes(objectId);
        const shiftSelection = objectListShiftSelection(store.getState());

        if (lastSelectedId && event.shiftKey) {
            const loadedObjects = loadedObjectsMap(store.getState());
            const listItems = Object.keys(loadedObjects);
            const lastSelectedIndex = listItems.indexOf(lastSelectedId);
            const clickedIndex = listItems.indexOf(objectId);

            if (lastSelectedIndex === -1 || clickedIndex === -1) {
                dispatch(selectObject({ objectId } as SelectObject));
                return;
            }

            const [start, end] =
                lastSelectedIndex < clickedIndex
                    ? [lastSelectedIndex, clickedIndex]
                    : [clickedIndex, lastSelectedIndex];

            shiftSelection.forEach((unselectObjectId) => {
                dispatch(
                    unselectObject({
                        objectId: unselectObjectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            });

            for (let index = start; index <= end; index += 1) {
                dispatch(
                    multiSelectObject({
                        objectId: listItems[index],
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            }
            return;
        }
        handleMultiSelect({
            event,
            selected,
            onMultiSelect: () => {
                dispatch(
                    multiSelectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
            onUnselect: () =>
                dispatch(
                    unselectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                ),
            onSelect: () => {
                dispatch(
                    selectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
        });
    };
    const handleDoubleClick = () => {
        const loadedObjects = loadedObjectsMap(store.getState());
        Object.keys(loadedObjects).forEach((selectObjectId) => {
            dispatch(multiSelectObject({ objectId: selectObjectId } as SelectObject));
        });
    };

    const handleRemoveItem = () => {
        if (hasMultipleObjectSelected) {
            const isObjectSelected = selectedObjectIds.includes(objectId);
            if (isObjectSelected) {
                const viewsToRemove = views.filter((view) =>
                    selectedObjectIds.some((selectedObjectId) => view.includes(selectedObjectId)),
                );
                viewsToRemove.forEach((view) => {
                    disposeEntity(view);
                });
                selectedObjectIds.forEach((selectedObjectId) => {
                    removeObjectFromStore(selectedObjectId);
                });
                return;
            }
        }
        removeObjectFromStore(objectId);
        disposeEntity(viewId);
    };
    const removeObjectFromStore = (objectIdToRemove: string) => {
        dispatch(removeFromLoadedObjects(objectIdToRemove));
        dispatch(unselectObject({ objectId: objectIdToRemove }));
    };
    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (isRendered && event.key === 'Delete') {
            handleRemoveItem();
        }
    };
    const handleZoomToView = () => {
        getZoomToViewTool().zoomToView(viewId);
    };

    const [isObjectVisible, setIsObjectVisible] = useState(false);
    useXyzListener(viewId, 'visible', setIsObjectVisible);
    const selectedObjectIds = useAppSelector(objectListSelection);
    const hasMultipleObjectSelected = selectedObjectIds.length > 1;
    const zoomToViewActive = isObjectVisible && !hasMultipleObjectSelected;

    return {
        handleObjectSelect,
        handleDoubleClick,
        handleKeyUp,
        handleRemoveItem,
        handleZoomToView,
        zoomToViewActive,
    };
}
