import { ListObjectsResponse } from '@local/api-clients/dist/goose/GENERATED_gooseClientEndpoints';
import { handleMultiSelect } from '@local/webviz/dist/utilities';
import { useState } from 'react';

import {
    clearSelectedObjects,
    multiSelectObject,
    selectObject,
    unselectObject,
} from 'src/store/visualization/visualizationSlice';
import { SelectObject } from 'src/store/visualization/visualizationSlice.types';

import { store, useAppDispatch } from '../../store/store';
import { loadedObjectsMap } from '../../store/visualization/selectors';
import { useObjectManager } from '../context/hooks/useObjectManager';

const OBJECT_PANEL = 'text/object-id';

export function useDrag() {
    const dispatch = useAppDispatch();
    const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([]);
    const [lastSelected, setLastSelected] = useState<number | null>(null);

    const onClick = (
        data: ListObjectsResponse,
        index: number,
        objectId: string,
        event: React.MouseEvent | React.DragEvent,
    ) => {
        if (event.type === 'click' && !event.shiftKey && !event.ctrlKey) {
            setSelectedObjectIds([objectId]);
            setLastSelected(index);
        }
        if (event.type === 'click' && event.ctrlKey) {
            setSelectedObjectIds(Array.from(new Set([...selectedObjectIds, objectId])));
            setLastSelected(index);
        }
        if (event.type === 'click' && event.shiftKey) {
            if (lastSelected !== null) {
                const start = Math.min(lastSelected, index);
                const end = Math.max(lastSelected, index);
                const newSelectedObjectIds = [...selectedObjectIds];
                for (let i = start; i <= end; i += 1) {
                    if (!newSelectedObjectIds.includes(data.objects[i].object_id)) {
                        newSelectedObjectIds.push(data.objects[i].object_id);
                    }
                }
                setSelectedObjectIds(newSelectedObjectIds);
                setLastSelected(index);
            } else {
                setSelectedObjectIds([objectId]);
                setLastSelected(index);
            }
        }

        handleMultiSelect({
            event: event as
                | React.MouseEvent<HTMLDivElement, MouseEvent>
                | React.DragEvent<HTMLDivElement>,
            selected: true,
            onMultiSelect: () => {
                dispatch(
                    multiSelectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
            onUnselect: () =>
                dispatch(
                    unselectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                ),
            onSelect: () => {
                dispatch(
                    selectObject({
                        objectId,
                        shift: event.shiftKey,
                    } as SelectObject),
                );
            },
        });
    };

    const onDragStart = (objectId: string, event: React.DragEvent<HTMLDivElement>) => {
        const selectedAndDragged = Array.from(new Set([...selectedObjectIds, objectId]));
        setSelectedObjectIds(selectedAndDragged);
        event.dataTransfer.setData(OBJECT_PANEL, JSON.stringify(selectedAndDragged));
        const imageElement = event.currentTarget.querySelector('svg');
        if (imageElement) {
            event.dataTransfer.setDragImage(imageElement, 0, 0);
        }
    };

    /**
     * Selects the entire list of objects when only one object is double-clicked.
     */
    const onDoubleClick = (objectIds: string[]) => {
        setSelectedObjectIds(objectIds);
    };

    const selectedListObjectIds = () => {
        const loadedObjects = Object.keys(loadedObjectsMap(store.getState()));
        return selectedObjectIds.filter((id) => !loadedObjects.includes(id));
    };

    const clearSelection = () => {
        setTimeout(() => {
            dispatch(clearSelectedObjects({}));
        }, 1000);
    };

    return {
        selectedListObjectIds,
        onClick,
        onDoubleClick,
        onDragStart,
        clearSelection,
    };
}

export function useDrop() {
    const { loadObject } = useObjectManager();
    const dispatch = useAppDispatch();

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const objectIds: string[] = JSON.parse(event.dataTransfer.getData(OBJECT_PANEL));
        for (const objectId of objectIds) {
            loadObject(objectId);
        }
        setTimeout(() => {
            dispatch(clearSelectedObjects({}));
        }, 1000);
    };

    return { onDrop };
}

export function onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'copy';
}
