import { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useContext } from 'react';

import { useAppSelector } from '../../../store/store';
import { objectListSelection } from '../../../store/visualization/selectors';
import { GooseListContext } from '../../context/gooseContext/gooseContext';
import { useObjectManager } from '../../context/hooks/useObjectManager';
import { useDrag } from '../../useDragAndDrop/useDragAndDrop';
import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './ProjectTreePanel.styles';

export function SkeletonObjectsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel() {
    const { classes } = useStyles();

    const { data } = useContext(GooseListContext);

    const { selectedListObjectIds, onClick, onDoubleClick, onDragStart, clearSelection } =
        useDrag();

    const storeSelectedObjectIds = useAppSelector(objectListSelection);
    const mergedSelectedIds = [...new Set([...selectedListObjectIds(), ...storeSelectedObjectIds])];

    const { isObjectOnPlotByObjectId } = useObjectManager('dummy');

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            {data.objects.map((child: ListedObject, index: number) => (
                <Grid
                    key={child.object_id}
                    onClick={(event) => onClick(data, index, child.object_id, event)}
                    onDragStart={(event) => {
                        onDragStart(child.object_id, event);
                    }}
                    onDoubleClick={() =>
                        onDoubleClick(data.objects.map((object: ListedObject) => object.object_id))
                    }
                    draggable
                    style={{
                        pointerEvents: isObjectOnPlotByObjectId(child.object_id) ? 'none' : 'auto',
                    }}
                >
                    <ObjectListItemControl
                        objectListItem={child}
                        selectedObjectIds={mergedSelectedIds}
                        clearSelection={() => clearSelection()}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
