import {
    GetObjectResponse,
    useLazyGetObjectByIdQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';

export const ID = 'id';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId?: string) {
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const dispatch = useAppDispatch();

    const [
        GetObjectByIdTrigger,
        { isLoading: isNetworkLoading, isSuccess, isError: isNetworkError },
    ] = useLazyGetObjectByIdQuery();
    const [isLoading, setIsLoading] = useState(isNetworkLoading);
    const [isError, setIsError] = useState(isNetworkLoading);
    useEffect(() => {
        if (isNetworkLoading) {
            setIsLoading(true);
            setIsError(false);
        } else if (isNetworkError) {
            setIsLoading(false);
            setIsError(true);
        }
    }, [isNetworkLoading, isNetworkError]);

    async function loadObject(objectId: string) {
        if (isObjectOnPlotByObjectId(objectId)) return;
        try {
            const gooseObject: GetObjectResponse = await GetObjectByIdTrigger({
                objectId,
                orgId,
                workspaceId,
            }).unwrap();
            dispatch(addToLoadedObjects(gooseObject));
        } catch (error) {
            trackError('Error loading goose object for visualization', JSON.stringify(error));
            // TODO: handle error ui
        }
    }

    function removeObject(objectId: string) {
        dispatch(removeFromLoadedObjects(objectId));
    }

    const { useXyzListener, addViewStatusListener } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    useEffect(() => {
        if (!listItemId) return () => {};
        const objectViewId = views.find((view) => view.includes(listItemId));
        if (!objectViewId) return () => {};

        const removeViewStatusListener = addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                setIsLoading(false);
                setIsError(false);
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                setIsError(true);
            },
            onPending: () => {
                setIsLoading(true);
                setIsError(false);
            },
        });
        return () => {
            removeViewStatusListener();
        };
    }, [views]);

    function isObjectOnPlotByObjectId(objectId: string) {
        return views.some((view) => view.includes(objectId));
    }

    return {
        loadObject,
        removeObject,
        isError,
        isLoading,
        isSuccess,
        isObjectOnPlotByObjectId,
    };
}
