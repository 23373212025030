import type { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { extractObjectSchemaName } from '@local/api-clients/dist/utils/extractObjectSchemaName';
import OpenIcon from '@local/web-design-system/dist/icons/Actions/OpenIcon';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { PropertyHeader } from 'src/components/sidebar/PropertyHeader';
import { PropertyText } from 'src/components/sidebar/PropertyText';
import { useStyles as useSidebarStyles } from 'src/components/sidebar/Sidebar.styles';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { FilterProperties } from 'src/pages/workspacePage/workspaceContent/sidebar/FilterProperties';
import {
    CREATED_BY,
    CREATED_ON,
    DATA_TYPE,
    OBJECT_ID,
    OBJECT_PATH,
    PROPERTIES,
    SOURCE,
    VERSION_ID,
    WEBVIZ_BUTTON,
} from 'src/strings';
import { WEBVIZ_PLOT } from 'src/urls';

import { ID } from '../../../../visualization/context/hooks/useObjectManager';
import { Icon } from './Icon';

interface Props {
    isFile: boolean;
    data?: GetObjectResponse;
    selectedFilter: Folder | null;
}

export const Properties = ({ isFile, data, selectedFilter }: Props) => {
    const [id] = usePersistedState('id');
    const { classes } = useStyles();
    const { classes: sidebarClasses } = useSidebarStyles();
    const { evouiEnableGooseRendering } = useFlags();

    if (!isFile) {
        // Show filter properties
        return <FilterProperties data={selectedFilter} />;
    }

    if (!data) {
        return null;
    }

    return (
        <div>
            <Header text={PROPERTIES} />
            <div className={classes.header}>
                <Icon schema={data.object.schema} />
                <Typography
                    className={classes.propertiesNameHeader}
                    automation-id="file-name"
                    title={data.object.name}
                >
                    {data.object.name}
                </Typography>
            </div>
            <div className={classes.properties}>
                <PropertyHeader text={DATA_TYPE} automationId="data-type" />
                <PropertyText
                    className={classes.capitalize}
                    text={extractObjectSchemaName(data.object.schema)}
                    automationId="data-type-value"
                />
                <PropertyHeader text={OBJECT_PATH} automationId="object-path" />
                <PropertyText text={data.object_path} automationId="object-path-value" />
                {data.object.source && (
                    <>
                        <PropertyHeader text={SOURCE} automationId="object-source" />
                        <PropertyText
                            text={data.object.source}
                            automationId="object-source-value"
                        />
                    </>
                )}
                <PropertyHeader text={VERSION_ID} automationId="version-id" />
                <PropertyText text={data.version_id} automationId="version-id-value" />
                <PropertyHeader text={OBJECT_ID} automationId="object-id" />
                <PropertyText text={data?.object_id} automationId="object-id-value" />
                <PropertyHeader text={CREATED_ON} automationId="created-on" />
                <PropertyDate timestamp={data.created_at} automationId="created-on-value" />
                <PropertyHeader text={CREATED_BY} automationId="created-by" />
                <PropertyText text={data.created_by?.name} automationId="created-by-value" />
                {data.object.tags &&
                    Object.keys(data.object.tags).map((key) => (
                        <>
                            <PropertyHeader text={key} key={key} />
                            <PropertyText text={data.object.tags[key]} key={`${key}-value}`} />
                        </>
                    ))}
            </div>
            {evouiEnableGooseRendering && (
                <Button
                    className={sidebarClasses.iconButton}
                    automation-id="go-to-plot-buttons-view"
                    size="large"
                    onClick={() => window.open(`${WEBVIZ_PLOT}?${ID}=${id}`, '_blank')}
                    variant="contained"
                    color="secondary"
                    fullWidth
                >
                    <OpenIcon />
                    <Typography variant="body2" paddingLeft="16px">
                        {WEBVIZ_BUTTON}
                    </Typography>
                </Button>
            )}
        </div>
    );
};
