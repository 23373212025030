/* eslint-disable no-param-reassign */

import { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { initialState } from './selectors';
import { SelectObject, VisualizationState } from './visualizationSlice.types';

export const visualizationSlice: Slice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        selectObject(state: VisualizationState, { payload }: PayloadAction<SelectObject>) {
            const { objectId } = payload;
            state.selectedObjects.selection = [objectId];
            state.selectedObjects.shiftSelection = [];
            state.selectedObjects.lastSelected = objectId;
        },
        multiSelectObject(state: VisualizationState, { payload }: PayloadAction<SelectObject>) {
            const { objectId, shift } = payload;
            const selection = state.selectedObjects.selection.includes(objectId)
                ? [...state.selectedObjects.selection]
                : [...state.selectedObjects.selection, objectId];

            const lastSelected = shift ? state.selectedObjects.lastSelected : objectId;
            const shiftSelection = shift
                ? [...state.selectedObjects.shiftSelection, objectId]
                : state.selectedObjects.shiftSelection;

            state.selectedObjects.selection = selection;
            state.selectedObjects.shiftSelection = shiftSelection;
            state.selectedObjects.lastSelected = lastSelected;
        },
        unselectObject(state: VisualizationState, { payload }: PayloadAction<SelectObject>) {
            const { objectId } = payload;
            if (!state.selectedObjects.selection.includes(objectId)) {
                return;
            }
            const selection = state.selectedObjects.selection.filter((id) => id !== objectId);

            state.selectedObjects.selection = selection;
            state.selectedObjects.lastSelected = objectId;
        },
        clearSelectedObjects(state: VisualizationState) {
            state.selectedObjects.selection = [];
            state.selectedObjects.shiftSelection = [];
            state.selectedObjects.lastSelected = undefined;
        },
        addToLoadedObjects(state: VisualizationState, action: PayloadAction<GetObjectResponse>) {
            const { payload } = action;
            const { object_id: objectId } = payload;
            if (!state.loadedObjects[objectId]) {
                state.loadedObjects[objectId] = payload;
            }
        },
        removeFromLoadedObjects(
            state: VisualizationState,
            { payload: objectId }: PayloadAction<string>,
        ) {
            if (state.loadedObjects[objectId]) {
                delete state.loadedObjects[objectId];
            }
        },
        updateOverlays(state: VisualizationState, { payload }: PayloadAction<VisualizationState>) {
            state.overlays = { ...state.overlays, ...payload };
        },
        clearLoadedObjects(state: VisualizationState) {
            state.loadedObjects = {};
        },
    },
});

export const {
    selectObject,
    multiSelectObject,
    unselectObject,
    clearSelectedObjects,
    addToLoadedObjects,
    removeFromLoadedObjects,
    updateOverlays,
    clearLoadedObjects,
} = visualizationSlice.actions;
