import { workspaceClientEndpoints } from './GENERATED_workspaceClientEndpoints';

export const enhancedWorkspaceClient = workspaceClientEndpoints.enhanceEndpoints({
    addTagTypes: ['Workspace', 'WorkspaceUser', 'Image'],
    endpoints: {
        createWorkspace: {
            invalidatesTags: (workspace) => [{ type: 'Workspace', id: workspace?.id }, 'Workspace'],
        },
        updateWorkspace: {
            invalidatesTags: (workspace) => [{ type: 'Workspace', id: workspace?.id }, 'Workspace'],
        },
        listWorkspaces: {
            providesTags: (response) =>
                response
                    ? [
                          ...response.results.map((workspace) => ({
                              type: 'Workspace' as const,
                              id: workspace?.id,
                          })),
                          'Workspace',
                      ]
                    : ['Workspace'],
        },
        getWorkspace: {
            providesTags: (workspace) => [{ type: 'Workspace', id: workspace?.id }],
        },
        deleteWorkspace: {
            invalidatesTags: (workspace, error, arg) => [
                { type: 'Workspace', id: arg.workspaceId },
                'Workspace',
            ],
        },
        getCurrentUserRole: {
            providesTags: (userRole) => [
                { type: 'WorkspaceUser', id: userRole?.user_id },
                'WorkspaceUser',
            ],
        },
        listUserRoles: {
            providesTags: (response) =>
                response
                    ? [
                          ...response.results.map((userRole) => ({
                              type: 'WorkspaceUser' as const,
                              id: userRole.user_id,
                          })),
                          'WorkspaceUser',
                      ]
                    : ['WorkspaceUser'],
        },
        assignUserRole: {
            invalidatesTags: (userRole, error, arg) => [
                { type: 'WorkspaceUser', id: userRole?.user_id },
                'WorkspaceUser',
                { type: 'Workspace', id: arg.workspaceId },
                'Workspace',
            ],
        },
        deleteUserRole: {
            invalidatesTags: (userRole, error, arg) => [
                { type: 'WorkspaceUser', id: arg.userId },
                'WorkspaceUser',
                { type: 'Workspace', id: arg.workspaceId },
                'Workspace',
            ],
        },
        // admin endpoints. Should share all cache logic with regular endpoints
        listWorkspacesAdmin: {
            providesTags: (response) =>
                response
                    ? [
                          ...response.results.map((workspace) => ({
                              type: 'Workspace' as const,
                              id: workspace?.id,
                          })),
                          'Workspace',
                      ]
                    : ['Workspace'],
        },
        getWorkspaceAdmin: {
            providesTags: (workspace) => [{ type: 'Workspace', id: workspace?.id }],
        },
        listUserRolesAdmin: {
            providesTags: (response) =>
                response
                    ? [
                          ...response.results.map((userRole) => ({
                              type: 'WorkspaceUser' as const,
                              id: userRole.user_id,
                          })),
                          'WorkspaceUser',
                      ]
                    : ['WorkspaceUser'],
        },
        listUserWorkspacesAdmin: {
            providesTags: (response) =>
                response
                    ? [
                          ...response.results.map((workspace) => ({
                              type: 'Workspace' as const,
                              id: workspace?.id,
                          })),
                          'Workspace',
                      ]
                    : ['Workspace'],
        },
        bulkAssignRolesAdmin: {
            invalidatesTags: (userRole) => [
                { type: 'WorkspaceUser', id: userRole?.user_id },
                'WorkspaceUser',
                'Workspace',
            ],
        },
        assignUserRoleAdmin: {
            invalidatesTags: (userRole, error, arg) => [
                { type: 'WorkspaceUser', id: userRole?.user_id },
                'WorkspaceUser',
                { type: 'Workspace', id: arg.workspaceId },
                'Workspace',
            ],
        },
        deleteUserRoleAdmin: {
            invalidatesTags: (userRole, error, arg) => [
                { type: 'WorkspaceUser', id: arg.userId },
                'WorkspaceUser',
                { type: 'Workspace', id: arg.workspaceId },
                'Workspace',
            ],
        },
        putThumbnail: {
            invalidatesTags: (_workspace, _error, arg) => [{ type: 'Image', id: arg?.workspaceId }],
        },
    },
});

export const {
    useListWorkspacesQuery,
    useLazyListWorkspacesQuery,
    useCreateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useGetWorkspaceQuery,
    useLazyGetWorkspaceQuery,
    useUpdateWorkspaceMutation,
    useGetCurrentUserRoleQuery,
    useLazyGetCurrentUserRoleQuery,
    useListUserRolesQuery,
    useLazyListUserRolesQuery,
    useAssignUserRoleMutation,
    useDeleteUserRoleMutation,
    // admin endpoints
    useListWorkspacesAdminQuery,
    useLazyListWorkspacesAdminQuery,
    useGetWorkspaceAdminQuery,
    useLazyGetWorkspaceAdminQuery,
    useListUserRolesAdminQuery,
    useLazyListUserRolesAdminQuery,
    useAssignUserRoleAdminMutation,
    useDeleteUserRoleAdminMutation,
    useListUserWorkspacesAdminQuery,
    useBulkAssignRolesAdminMutation,
} = enhancedWorkspaceClient;
