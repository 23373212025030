import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { RETRIES, WAIT_TIME_MS } from 'src/constants';

import { enhancedFileClient } from './enhancedFileMiddleware';
import { fileClient } from './fileClient';
import type {
    GetFileByIdApiResponse,
    GetFileByPathApiResponse,
    UpdateFileByIdApiArg,
    UpsertFileByPathApiArg,
} from './GENERATED_fileClientEndpoints';
import { waitForMs } from './utils';

interface CustomUpdateFilesByIdApiArgs extends UpdateFileByIdApiArg {
    updatedVersion: File;
}

interface CustomUpsertFileByPathApiArgs extends UpsertFileByPathApiArg {
    uploadFile: File;
}

const uploadWithPolling = async (
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
) => {
    const { workspaceId, organisationId } = args;

    // Get pre-signed Azure upload URL
    let response;
    if ('fileId' in args) {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.updateFileById.initiate({
                fileId: args.fileId,
                organisationId,
                workspaceId,
            }),
        );
    } else {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.upsertFileByPath.initiate({
                filePath: args.filePath,
                organisationId,
                workspaceId,
            }),
        );
    }

    if ('error' in response) {
        const message =
            'status' in response.error && response.error.status === 410
                ? 'File has been deleted in the background'
                : 'Failed to upload file to file service';
        const error = new Error(message) as unknown as FetchBaseQueryError;
        return { error };
    }

    // Track the new version ID, so we can wait until it's ready
    const {
        data: { file_id: fileId, upload, version_id: versionId },
    } = response;

    // Upload the file to blob storage
    const fetchBody = 'fileId' in args ? args.updatedVersion : args.uploadFile;
    await fetch(upload, {
        method: 'PUT',
        headers: {
            'x-ms-blob-type': 'BlockBlob',
        },
        body: fetchBody,
    });

    // We need to wait for the file to be uploaded to Azure before returning the response
    /* eslint-disable no-await-in-loop */
    let fetchCount = 0;

    while (fetchCount < RETRIES) {
        fetchCount += 1;

        await waitForMs(WAIT_TIME_MS);

        const {
            data: newFile,
            isError,
            error: fetchError,
        } = await queryApi.dispatch(
            enhancedFileClient.endpoints.getFileById.initiate(
                {
                    organisationId,
                    workspaceId,
                    fileId,
                    versionId,
                    includeVersions: true,
                },
                { forceRefetch: true },
            ),
        );

        if (isError && 'status' in fetchError && fetchError.status === 410) {
            const error = new Error(
                'File has been deleted in the background',
            ) as unknown as FetchBaseQueryError;
            return { error };
        }

        // When the new file version is available we can invalidate the "get file by ID" cache for
        // this file, triggering a re-fetch (which shows the new version), then return the new file
        if (newFile) {
            return { data: newFile };
        }
    }

    const error = new Error(
        `File upload failed after ${(RETRIES * WAIT_TIME_MS) / 1000} seconds`,
    ) as unknown as FetchBaseQueryError;
    return { error };
};

export const injectedFileClient = fileClient.injectEndpoints({
    endpoints: (build) => ({
        customUploadFileById: build.mutation<GetFileByIdApiResponse, CustomUpdateFilesByIdApiArgs>({
            /**
             * Upsert a file by id. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
        customUpsertFileByPath: build.mutation<
            GetFileByPathApiResponse,
            CustomUpsertFileByPathApiArgs
        >({
            /**
             * Upsert a file by path. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
    }),
});

export const { useCustomUploadFileByIdMutation, useCustomUpsertFileByPathMutation } =
    injectedFileClient;
