import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    base: {
        cursor: 'default',
        bottom: '24px',
        right: '24px',
        width: '400px',
        zIndex: 400,
        position: 'absolute',
        backgroundColor: '#1d1f23',
        color: 'white',
        borderRadius: '8px',
        padding: '16px',
        gap: '16px',
        boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
    },
    detailsBase: {
        backgroundColor: '#282a2e',
        boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
        borderRadius: '8px',
        padding: '16px',
    },
    closeButton: {
        width: '28px',
        height: '28px',
        backgroundColor: '#4a4a4c',
        borderRadius: '50% !important',
        padding: '8px',
    },
    webvizButton: {
        color: 'white',
        backgroundColor: '#348ed1',
        borderRadius: '4px !important',
    },
    divider: {
        borderColor: '#4a4a4c',
        borderWidth: '1px',
    },
    descriptionContainer: {
        padding: '12px',
        backgroundColor: '#1d1f23',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: `0 0 10px 0 rgba(0,0,0,0.45) inset`,
    },
    descriptionTextContainer: {
        overflowY: 'scroll',
        maxHeight: '200px',
    },
    descriptionText: {
        textWrap: 'wrap',
    },
    descriptionSkeleton: {
        borderRadius: '8px',
    },
    closeIcon: {
        height: '100%',
        width: '100%',
        color: 'white',
    },
}));
