import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import {
    SelectionListState,
    SelectorTypeBoolean,
    SelectorTypeGetObjectResponse,
    SelectorTypeObjects,
    SelectorTypeString,
    SelectorTypeStringArray,
    VisualizationState,
} from './visualizationSlice.types';

const initialSelectionState: SelectionListState = {
    selection: [],
    shiftSelection: [],
};

export const initialState: VisualizationState = {
    selectedObjects: initialSelectionState,
    loadedObjects: {},
    overlays: { scalebar: true, orientation: true },
};

const visualizationState = (state: RootState): VisualizationState =>
    state.visualization ?? initialState;

export const scalebarVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.scalebar,
);

export const orientationVisible: SelectorTypeBoolean = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.overlays.orientation,
);

export const loadedObjectsMap: SelectorTypeObjects = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.loadedObjects,
);

export const loadedObjectById = (objectId: string): SelectorTypeGetObjectResponse =>
    createSelector(
        visualizationState,
        (visualizationStateRoot) => visualizationStateRoot.loadedObjects[objectId],
    );

export const objectListSelection: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.selectedObjects.selection,
);
export const objectListShiftSelection: SelectorTypeStringArray = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.selectedObjects.shiftSelection,
);

export const lastSelectedObjectId: SelectorTypeString = createSelector(
    visualizationState,
    (visualizationStateRoot) => visualizationStateRoot.selectedObjects.lastSelected ?? '',
);

export const getObjectSchemaByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => response?.object.schema ?? '');

export const getObjectNameByObjectId = (objectId: string): SelectorTypeString =>
    createSelector(loadedObjectById(objectId), (response) => response?.object.name ?? '');
