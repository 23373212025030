import {
    OrgListedObject,
    useListObjectsByOrgQuery,
} from '@local/api-clients/dist/goose/enhancedGooseClient';
import { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { capBounds } from '@local/map-viewer/dist/utils/capBounds';
import { getGeoJSONBoundingBox } from '@local/map-viewer/dist/utils/getGeoJSONBoundingBox';
import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

interface Context {
    isLoading: boolean;
    isFetching: boolean;
    objects: OrgListedObject[];
    activeObject?: OrgListedObject;
    setActiveObject: (objectID?: string) => void;
    toggleActiveObject: (objectID?: string) => void;
    setSearchBounds: (bounds?: BoundingBoxCoords | null) => void;
}

export const DiscoverContext = createContext<Context>({
    objects: [],
    isLoading: false,
    isFetching: false,
    activeObject: undefined,
    setActiveObject: () => {},
    setSearchBounds: () => {},
    toggleActiveObject: () => {},
});

export const useDiscoverContext = () => useContext(DiscoverContext);

interface Props {
    children: React.ReactNode;
}

export const DiscoverProvider = ({ children }: Props) => {
    const { orgUuid } = useParams();
    const [activeObject, setActiveObject] = useState<OrgListedObject>();
    const [activeObjectID, setActiveObjectID] = useState<string>();
    const [objects, setObjects] = useState<OrgListedObject[]>([]);
    const [bounds, setSearchBounds] = useState<BoundingBoxCoords | null>();

    const queryBounds = useMemo(
        () => (bounds ? getGeoJSONBoundingBox(capBounds(bounds)) : undefined),
        [bounds],
    );

    const { data, isLoading, isFetching } = useListObjectsByOrgQuery({
        orgId: orgUuid ?? '',
        geojsonBoundingBox: queryBounds ? [queryBounds] : undefined,
    });

    useEffect(() => {
        if (!data?.objects || isLoading || isFetching) {
            return;
        }
        setObjects(data.objects);
    }, [data?.objects, isLoading, isFetching]);

    useEffect(() => {
        if (!objects || !activeObjectID) {
            setActiveObject(undefined);
            return;
        }

        const object = objects.find((obj: OrgListedObject) => obj.object_id === activeObjectID);
        if (object) {
            setActiveObject(object);
        } else {
            setActiveObject(undefined);
            setActiveObjectID(undefined);
        }
    }, [activeObjectID, objects]);

    const toggleActiveObject = useCallback(
        (objectID?: string) => {
            setActiveObjectID((prevID) => (prevID === objectID ? undefined : objectID));
        },
        [setActiveObjectID],
    );

    return (
        <DiscoverContext.Provider
            value={useMemo(
                () => ({
                    objects,
                    isLoading,
                    isFetching,
                    activeObject,
                    setSearchBounds,
                    toggleActiveObject,
                    setActiveObject: setActiveObjectID,
                }),
                [objects, isLoading, isFetching, activeObject, setSearchBounds, setActiveObjectID],
            )}
        >
            {children}
        </DiscoverContext.Provider>
    );
};

export function withDiscoverContext(child: React.ReactNode) {
    return <DiscoverProvider>{child}</DiscoverProvider>;
}
